import type { RouteRecordRaw } from 'vue-router';

const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/device',
    component: () => import('/@/views/device/index.vue'),
    name: 'device',
    meta: { title: '设备' },
  },
  {
    path: '/report',
    component: () => import('/@/views/report/index.vue'),
    name: 'report',
    meta: { title: '报修' },
  },
  {
    path: '/reportDetail',
    component: () => import('/@/views/reportDetail/index.vue'),
    name: 'reportDetail',
    meta: { title: '报修详情' },
  },
  {
    path: '/location',
    component: () => import('/@/views/reportDetail/location.vue'),
    name: 'location',
    meta: { title: '查看维修地点' },
  },
  {
    path: '/evaluate',
    component: () => import('/@/views/evaluate/index.vue'),
    name: 'evaluate',
    meta: { title: '评价' },
  },
  {
    path: '/choseLocation',
    component: () => import('/@/views/choseLocation/index.vue'),
    name: 'choseLocation',
    meta: { title: '选择位置' },
  },
  {
    path: '/viewDevice',
    component: () => import('/@/views/viewDevice/index.vue'),
    name: 'viewDevice',
    meta: { title: '设备信息查看' },
  },
  {
    path: '/viewDeviceDetail',
    component: () => import('/@/views/viewDeviceDetail/index.vue'),
    name: 'viewDeviceDetail',
    meta: { title: '设备详情' },
  },
  {
    path: '/',
    name: 'Root',
    redirect: '/report',
    meta: {
      title: 'Root',
    },
  },
];


// /**
//  * 基础路由
//  * @type { *[] }
//  */
// export const constantRouterMap = [];

export default constantRoutes;
